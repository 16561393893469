import {createRouter, createWebHistory} from 'vue-router'
import app from "@/main";

const routes = [
    {path: '/', redirect: '/dashboard'},
    {path: '/dashboard', name: 'Startseite', component: () => import('../views/HomeView.vue')},
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/Login.vue'),
        meta: {
            hide: {
                sidebar: true,
                navbar: true,
                footer: true
            }
        }
    },
    {path: '/group/:groupId', name: 'Group Details', component: () => import('@/views/GroupDetail.vue')},
    {path: '/team-manager', name: 'Team Manager', component: () => import('@/views/TeamManager.vue')},
    {path: '/user', name: 'User', component: () => import('@/views/User.vue')},
    {path: '/user/:userId', name: 'User Details', component: () => import('@/views/UserDetails.vue')},
    {path: '/charts', name: 'chart', component: () => import('../views/Chart.vue')},
    {path: '/tickets/support', name: 'Support Tickets', component: () => import('@/views/tickets/SupportTicket.vue')},
    {path: '/system/log', name: 'Dashboard Log', component: () => import('@/views/system/Log.vue')},
    {path: '/system/security', name: 'Discord Security', component: () => import('@/views/system/Security.vue')},
    {path: '/system/banlist', name: 'Bann liste', component: () => import('@/views/BanList.vue')},
    {path: '/system/unban', name: 'Unban', component: () => import('@/views/system/Unban.vue')}
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach(async (to, from) => {
    if (localStorage.getItem('access') == null && to.path !== '/login') {
        return '/login'
    }
    if (localStorage.getItem('access') != null && to.path === '/login')
        return '/'
    if (app.$api)
        app.$api.createLogEntry(to.fullPath)
    document.title = to.name
    return true
})

export default router
