<template>
  <div class="modal fade" :id="id" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div v-if="title && title.length > 0" class="modal-header bg-secondary">
          <h5 class="modal-title" id="staticBackdropLabel">{{title}}</h5>
          <i class="fa-solid fa-xmark" @click="$emit('close')" aria-label="Close"></i>
        </div>
        <div class="bg-secondary p-2">
          <slot/>
        </div>
        <template v-if="!removeButtons">
          <div class="modal-footer bg-secondary">
            <button type="button" class="btn btn-outline-danger" :disabled="loading" @click="click('close')"><template v-if="!loading">Abbrechen</template><template v-if="loading"><LoadingSpinner/></template></button>
            <button type="button" class="btn btn-outline-success" :disabled="loading" @click="click('submit')"><template v-if="!loading">Bestätigen</template><template v-if="loading"><LoadingSpinner/></template></button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import {Modal} from "bootstrap";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default {
  components: {LoadingSpinner},
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    title: {
      type: String,
      required: false
    },
    open: {
      type: Boolean,
      required: false
    },
    id: {
      type: String,
      required: false
    },
    removeButtons: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      modal: {}
    }
  },
  methods: {
    click(type) {
      if (this.loading)
        return
      this.$emit(type)
    }
  },
  mounted() {
    this.modal = new Modal(document.getElementById(this.id), {keyboard: false, backdrop: 'static'})
  },
  watch: {
    open(newValue) {
      this.modal.toggle()
    }
  }
}
</script>