const api = {
    ready: false,
    readyCallbacks: [],
    onReady(callback) {
        if (this.ready)
            callback();
        else
            api.readyCallbacks.push(callback)
    },
    async install(app, options) {
        const api = {
            login: async (access) => {
                try {
                    const response = await app.axios.post('/auth/login', {
                        redirect_uri: window.location.origin + window.location.pathname,
                        code: access
                    })
                    if (response.status === 401) {
                        app.$toast.show({
                            color: 'danger',
                            title: 'Keinen Zugriff',
                            message: 'Du hast leider keinen Zugriff auf dieses System'
                        })
                        return false
                    }
                    localStorage.setItem('access', response.data.access)
                    localStorage.setItem('expires', response.data.expires)
                    localStorage.setItem('refresh', response.data.refresh)
                    /*await api.loadMe()
                    app.$toast.show({
                        color: 'success',
                        title: 'Login',
                        message: 'Willkommen zurück ' + api.getUsername()
                    })*/
                    return true;
                } catch (e) {
                    return false;
                }
            },
            listGuilds: async () => {
                try {
                    return (await app.axios.get('/auth/guilds')).data
                } catch (e) {
                    return []
                }
            },
            getCurrentGuildName: () => {
                return localStorage.getItem('guild_name')
            },
            getFeatures: () => {
                return localStorage.getItem('features') !== undefined ? JSON.parse(localStorage.getItem('features')) : []
            },
            loadPermissions: async () => {
                const response = await app.axios.get('/auth/' + localStorage.getItem('guild') + '/permissions')
                localStorage.setItem('permissions', JSON.stringify(response.data.permissions))
                localStorage.setItem("highestgroup", JSON.stringify(response.data.highest))
            },
            loadMe: async () => {
                try {
                    const response = await app.axios.get('/auth/me')
                    if (response === undefined || response.status !== 200) {
                        localStorage.clear()
                        app.config.globalProperties.$router.push('/')
                        return
                    }
                    localStorage.setItem('avatar', response.data.avatar)
                    localStorage.setItem('id', response.data.id)
                    localStorage.setItem('username', response.data.username)
                } catch (e) {
                }
            },
            getHighestGroup: () => {
                return JSON.parse(localStorage.getItem('highestgroup'))
            },
            getUsername: () => {
                return localStorage.getItem('username')
            },
            getId: () => {
                return localStorage.getItem('id')
            },
            getAvatar: () => {
                return localStorage.getItem('avatar')
            },
            getCurrentUsers: async () => {
                const response = await app.axios.get('/' + localStorage.getItem('guild') + '/stats/user/current')
                return response.data
            },
            getCurrentChannels: async () => {
                const response = await app.axios.get('/' + localStorage.getItem('guild') + '/stats/channels/current')
                return response.data
            },
            getCurrentStaff: async () => {
                const response = await app.axios.get('/' + localStorage.getItem('guild') + '/stats/staff/current')
                return response.data
            },
            getUsers: async () => {
                const response = await app.axios.get('/' + localStorage.getItem('guild') + '/member')
                return response.data
            },
            getUserCharts: async () => {
                const response = await app.axios.get('/' + localStorage.getItem('guild') + '/stats/user')
                return response.data
            },
            hasPermission: (permission) => {
                let has = false
                const perms = JSON.parse(localStorage.getItem('permissions'))
                for (let perm of perms) {
                    let regex = new RegExp(perm.toString().replaceAll('*', '(.*)'));
                    if (regex.test(permission)) {
                        has = true
                        break
                    }
                }
                return has
            },
            checkPermissions: (check, perms) => {
                let has = false
                for (let perm of perms) {
                    let regex = new RegExp(perm.toString().replaceAll('*', '(.*)'));
                    if (regex.test(check)) {
                        has = true
                        break
                    }
                }
                return has
            },
            getAllMember: async () => {
                const response = await app.axios.get('/' + localStorage.getItem('guild') + '/member')
                return response.data
            },
            getSpecificUser: async (userId) => {
                const response = await app.axios.get('/' + localStorage.getItem('guild') + '/member/' + userId)
                return response.data
            },
            removeRoleFromMember: async (userId, roleId) => {
                const response = await app.axios.delete('/' + localStorage.getItem('guild') + '/member/' + userId + '/roles/' + roleId)
                return response.data
            },
            addRoleToMember: async (userId, roleId) => {
                const response = await app.axios.put('/' + localStorage.getItem('guild') + '/member/' + userId + '/roles', {roleId})
                return response.data
            },
            listRoles: async () => {
                const response = await app.axios.get('/' + localStorage.getItem('guild') + '/role')
                return response.data
            },
            listAvailableRoles: async () => {
                const response = await app.axios.get('/' + localStorage.getItem('guild') + '/role/available')
                return response.data
            },
            getUserActivityScore: async (memberId) => {
                const response = await app.axios.get('/' + localStorage.getItem('guild') + '/member/' + memberId + '/score')
                return response.data
            },
            getLastMessagesFromUser: async (memberId, count) => {
                const response = await app.axios.post('/' + localStorage.getItem('guild') + '/message/' + memberId, {limit: count})
                return response.data
            },
            getTeamMemberDetails: async (memberId) => {
                const response = await app.axios.get('/' + localStorage.getItem('guild') + '/member/' + memberId + '/team-details')
                return response.data
            },
            listTeamMember: async () => {
                const response = await app.axios.get('/' + localStorage.getItem('guild') + '/team')
                return response.data
            },
            createLogEntry: async (page) => {
                if (localStorage.getItem('access') === null || localStorage.getItem('guild') === null)
                    return
                try{
                    await app.axios.post('/'+localStorage.getItem('guild')+'/log', {page})
                }catch (e) {
                }
            },
            listGroups: async () => {
                const response = await app.axios.get('/' + localStorage.getItem('guild') + '/group')
                return response.data
            },
            getGroup: async (groupId) => {
                const response = await app.axios.get('/' + localStorage.getItem('guild') + '/group/' + groupId)
                return response.data
            },
            createGroups: async (name, level) => {
                const response = await app.axios.put('/' + localStorage.getItem('guild') + '/group', {name, level})
                return response.data
            },
            getPublicPermissions: async () => {
                const response = await app.axios.get('permission')
                return response.data
            },
            addPermissionToGroup: async (groupId, permissions) => {
                const response = await app.axios.post('/' + localStorage.getItem('guild') + '/group/' + groupId + '/permission', {permissions})
                return response.data
            },
            addRoleToGroup: async (groupId, roleId) => {
                const response = await app.axios.post('/' + localStorage.getItem('guild') + '/group/' + groupId + '/roles/' + roleId)
                return response.data
            },
            removePermissionFromGroup: async (groupId, permissions) => {
                return await app.axios.delete('/' + localStorage.getItem('guild') + '/group/' + groupId + '/permission', {data: {permissions}})
            },
            disconnectMember: async (memberId) => {
                return await app.axios.post('/' + localStorage.getItem('guild') + '/member/' + memberId + '/disconnect')
            },
            listChannels: async () => {
                return await app.axios.get('/' + localStorage.getItem('guild') + '/channel')
            },
            kickUser: async (memberId) => {
                return await app.axios.post('/' + localStorage.getItem('guild') + '/member/' + memberId + '/kick')
            },
            timeoutUser: async (memberId, amount, unit) => {
                return await app.axios.post('/' + localStorage.getItem('guild') + '/member/' + memberId + '/timeout', {
                    for: amount,
                    unit
                })
            },
            removeTimeout: async (memberId) => {
                return await app.axios.delete('/' + localStorage.getItem('guild') + '/member/' + memberId + '/timeout')
            },
            getGuildConfig: async () => {
                return await app.axios.get('/' + localStorage.getItem('guild') + '/guild/config')
            },
            updateGuildSecurityConfig: async (securityConfig) => {
                return await app.axios.post('/'+localStorage.getItem('guild') + '/guild/config/security', securityConfig)
            },
            updateGuildUnbanConfig: async (unbanConfig) => {
                return await app.axios.post('/'+localStorage.getItem('guild') + '/guild/config/unban', unbanConfig)
            },
            retrieveBanlist: async () => {
                return await app.axios.get('/'+localStorage.getItem('guild') + '/guild/bans')
            },
            listLinkedDiscords: async () => {
                return await app.axios.get('/'+localStorage.getItem('guild') + '/guild/linked')
            }
        }
        if (localStorage.getItem('access') != null){
            await api.loadMe()
            await api.loadPermissions()
            localStorage.setItem('features', JSON.stringify((await api.getGuildConfig()).data.features))
        }

        app.config.globalProperties.$api = api
        app.$api = api
        this.ready = true
        this.runReadyCallbacks()
    },
    runReadyCallbacks() {
        api.readyCallbacks.forEach(callback => callback());
        api.readyCallbacks = [];
    },
    isReady: () => {
        if (api.ready) {
            return Promise.resolve();
        } else {
            return new Promise(resolve => {
                api.onReady(resolve);
            });
        }
    }
}
export default api