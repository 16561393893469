<template>
  <div id="sidebar" class="sidebar pe-4 pb-3">
    <nav class="navbar bg-secondary navbar-dark">
      <router-link to="/" tag="a" class="navbar-brand mx-4 mb-3"><h3 class="text-primary"><img width="50" height="50"
                                                                                               src="/img/logo.png">{{ $api.getCurrentGuildName() }}
      </h3></router-link>
      <div class="d-flex align-items-center ms-4 mb-4">
        <div class="position-relative">
          <img class="rounded-circle" :src="$api.getAvatar()" alt="" style="width: 40px; height: 40px;">
          <div
              class="bg-success rounded-circle border border-2 border-white position-absolute end-0 bottom-0 p-1"></div>
        </div>
        <div class="ms-3">
          <h6 class="mb-0">{{ $api.getUsername() }}</h6>
          <span>{{ $api.getHighestGroup().name }}</span>
        </div>
      </div>
      <div class="navbar-nav w-100">
        <template v-for="nav in navigation" :key="nav.name">
          <template v-if="!nav.feature || $api.getFeatures().includes(nav.feature)">
            <template v-if="!nav.permission || $api.hasPermission(nav.permission)">
              <template v-if="!nav.dropdown">
                <router-link tag="a" :to="nav.to" class="nav-item nav-link" :class="{'active': nav.active}"><i
                    class="fa me-2" :class="nav.icon"></i>{{ nav.name }}
                </router-link>
              </template>
              <template v-else>
                <div class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown"><i class="fa me-2" :class="nav.icon"></i>
                    {{ nav.name }}</a>
                  <div class="dropdown-menu bg-transparent border-0">
                    <template v-for="dropdown in nav.dropdown">
                      <template v-if="!dropdown.feature || $api.getFeatures().includes(dropdown.feature)">
                        <template v-if="!dropdown.permission || $api.hasPermission(dropdown.permission)">
                          <router-link :to="dropdown.to" tag="a" class="dropdown-item">
                            {{ dropdown.name }}
                          </router-link>
                        </template>
                      </template>
                    </template>
                  </div>
                </div>
              </template>
            </template>
          </template>
        </template>
      </div>
    </nav>
  </div>
</template>
<script setup>
import {onMounted, ref, watch} from "vue";
import {useRoute} from "vue-router";

const route = useRoute()

const navigation = ref([
  {name: 'Dashboard', to: '/dashboard', icon: 'fa-tachometer-alt', active: false},
  {name: 'Team Manager', to: '/team-manager', icon: 'fa-users-gear', permission: 'team.enter', active: false},
  {name: 'Users', to: '/user', icon: 'fa-users', permission: 'users.enter', active: false},
  {name: 'Charts', to: '/charts', icon: 'fa-chart-simple', permission: 'charts.enter', feature: 'charts', active: false},
  {name: 'Music', to: '/music', icon: 'fa-music', permission: 'music.enter', feature: 'music', active: false},
  {
    name: 'System', icon: 'fa-gear', permission: 'system.enter', feature: 'system', active: false, dropdown: [
      {name: 'Log', to: '/system/log', permission: 'system.log.view',feature: 'system.log', active: false},
      {name: 'Sicherheit', to: '/system/security', permission: 'system.security.view', feature: 'system.security', active: false},
      {name: 'Bann Liste', to: '/system/banlist', permission: 'system.banlist.view', feature: 'system.banlist', active: false},
      {name: 'Unban', to: '/system/unban', permission: 'system.unban.view', feature: 'system.unban', active: false},
      {name: 'Warns', to: '/system/warns', permission: 'system.warn.view', feature: 'system.warn', active: false},
    ]
  },
  {
    name: 'Tickets', icon: 'fa-ticket', permission: 'tickets.enter', feature: 'ticket', active: false, dropdown: [
      {name: 'Support', to: '/tickets/support', permission: 'tickets.support.enter', active: false},
      {name: 'Team', to: '/tickets/team', permission: 'tickets.team.enter', active: false},
      {name: 'Fortnite', to: '/tickets/fortnite', permission: 'tickets.fortnite.enter', active: false},
      {name: 'Valorant', to: '/tickets/valorant', permission: 'tickets.valorant.enter', active: false}
    ]
  },

])

const updateNav = (r) => {
  const active = navigation._value.find(n => n.active)
  if (active !== undefined) {
    active.active = false
    if (active.dropdown) {
      for (let filterElement of active.dropdown.filter(d => d.active)) {
        filterElement.active = false
      }
    }
  }
  const find = navigation.value.find(n => r.path.startsWith(n.to))
  if (find === undefined)
    return
  find.active = true
}

onMounted(() => {
  updateNav(useRoute())
})

watch(() => route.path, () => {
  updateNav(route)
});

</script>