<template>

  <!-- Navbar Start -->
  <nav class="navbar navbar-expand bg-secondary navbar-dark sticky-top px-4 py-0">
    <a href="index.html" class="navbar-brand d-flex d-lg-none me-4">
      <h2 class="text-primary mb-0"><i class="fa fa-user-edit"></i></h2>
    </a>
    <a @click="toggleNavbar" class="sidebar-toggler flex-shrink-0">
      <i class="fa fa-bars"></i>
    </a>
    <!--form class="d-none d-md-flex ms-4">
      <input class="form-control bg-dark border-0" type="search" placeholder="Search">
    </form-->
    <div class="navbar-nav align-items-center ms-auto">
      <!--div class="nav-item dropdown">
        <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">
          <i class="fa fa-envelope me-lg-2"></i>
          <span class="d-none d-lg-inline-flex">Message</span>
        </a>
        <div class="dropdown-menu dropdown-menu-end bg-secondary border-0 rounded-0 rounded-bottom m-0">
          <a href="#" class="dropdown-item">
            <div class="d-flex align-items-center">
              <img class="rounded-circle" src="img/user.jpg" alt="" style="width: 40px; height: 40px;">
              <div class="ms-2">
                <h6 class="fw-normal mb-0">Jhon send you a message</h6>
                <small>15 minutes ago</small>
              </div>
            </div>
          </a>
          <hr class="dropdown-divider">
          <a href="#" class="dropdown-item">
            <div class="d-flex align-items-center">
              <img class="rounded-circle" src="img/user.jpg" alt="" style="width: 40px; height: 40px;">
              <div class="ms-2">
                <h6 class="fw-normal mb-0">Jhon send you a message</h6>
                <small>15 minutes ago</small>
              </div>
            </div>
          </a>
          <hr class="dropdown-divider">
          <a href="#" class="dropdown-item">
            <div class="d-flex align-items-center">
              <img class="rounded-circle" src="img/user.jpg" alt="" style="width: 40px; height: 40px;">
              <div class="ms-2">
                <h6 class="fw-normal mb-0">Jhon send you a message</h6>
                <small>15 minutes ago</small>
              </div>
            </div>
          </a>
          <hr class="dropdown-divider">
          <a href="#" class="dropdown-item text-center">See all message</a>
        </div>
      </div-->
      <!--div class="nav-item dropdown">
        <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">
          <i class="fa fa-bell me-lg-2"></i>
          <span class="d-none d-lg-inline-flex">Notificatin</span>
        </a>
        <div class="dropdown-menu dropdown-menu-end bg-secondary border-0 rounded-0 rounded-bottom m-0">
          <a href="#" class="dropdown-item">
            <h6 class="fw-normal mb-0">Profile updated</h6>
            <small>15 minutes ago</small>
          </a>
          <hr class="dropdown-divider">
          <a href="#" class="dropdown-item">
            <h6 class="fw-normal mb-0">New user added</h6>
            <small>15 minutes ago</small>
          </a>
          <hr class="dropdown-divider">
          <a href="#" class="dropdown-item">
            <h6 class="fw-normal mb-0">Password changed</h6>
            <small>15 minutes ago</small>
          </a>
          <hr class="dropdown-divider">
          <a href="#" class="dropdown-item text-center">See all notifications</a>
        </div>
      </div-->
      <div class="nav-item dropdown">
        <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">
          <img class="rounded-circle me-lg-2" :src="$api.getAvatar()" alt="" style="width: 40px; height: 40px;">
          <span class="d-none d-lg-inline-flex">{{ $api.getUsername() }}</span>
        </a>
        <div class="dropdown-menu dropdown-menu-end bg-secondary border-0 rounded-0 rounded-bottom m-0">
          <!--a href="#" class="dropdown-item">My Profile</a>
          <a href="#" class="dropdown-item">Settings</a-->
          <a v-if="canSwitchDiscord" class="dropdown-item" @click="guildSwitchOpen = true">Discord wechseln</a>
          <a @click="logout" class="dropdown-item">Log Out</a>
        </div>
      </div>
    </div>
  </nav>
  <Modal :open="guildSwitchOpen" title="Discord wechseln" id="guild-switch" :remove-buttons="true">
    <div class="d-flex justify-content-around flex-wrap">
      <div v-for="guild of getGuilds()">
        <div @click="selectGuild(guild)" class="d-flex flex-column justify-content-center align-items-center p-2 guild-select" style="max-width: 150px; width: 150px; height: 150px; max-height: 150px;">
          <img v-if="guild.image && guild.image.length > 0" :src="guild.image" width="75" class="rounded-circle">
          <div v-if="!guild.image || guild.image.length === 0" class="circle">{{ getGuildInitials(guild.name) }}</div>
          <span class="mt-2 text-center">{{guild.name}}</span>
        </div>
      </div>
    </div>
  </Modal>
  <!-- Navbar End -->
</template>
<script setup>
import {useRouter} from "vue-router";
import {onMounted, ref} from "vue";
import Modal from "@/components/modal/Modal.vue";
import app from "@/main";
const router = useRouter()
const canSwitchDiscord = ref(false)
const guildSwitchOpen = ref(false)
const guilds = ref([])

onMounted(() => {
  canSwitchDiscord.value = localStorage.getItem('guilds') !== null
  guilds.value = JSON.parse(localStorage.getItem('guilds'))
})

const getGuilds = () => {
  return JSON.parse(localStorage.getItem('guilds'))
}

const getGuildInitials = (name) => {
  if(name === undefined)
    return ''
  let initials = ''
  for (let initial of name.split(' ')) {
    initials += initial[0]
  }
  return initials
}

const selectGuild = async (guild) => {
  guildSwitchOpen.value = false
  localStorage.setItem('guild', guild.id)
  localStorage.setItem('guild_name', guild.name)
  await app.$api.loadMe()
  await app.$api.loadPermissions()
  const features = await app.$api.getGuildConfig()
  if(features != null)
    localStorage.setItem('features', JSON.stringify(features.data.features))
  app.config.globalProperties.$router.push('/')
  window.location.reload()
}

const toggleNavbar = () => {
  document.getElementById("sidebar").classList.toggle('open')
  document.getElementById("sidebar-content").classList.toggle('open')
}

const logout = () => {
  localStorage.clear()
  router.push('/login')
}
</script>

<style>


.guild-select {
  border-radius: 10px;
}

.guild-select:hover {
  cursor: pointer;
  background-color: #343a40;
}

.circle {
  width: 90px;
  border-radius: 50%;
  font-family: roboto, arial;
  color: white;
  line-height: 90px;
  text-align: center;
  background: #313338;

}
</style>