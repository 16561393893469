import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import axios from "axios";
import VueAxios from "vue-axios";
import FloatingVue from 'floating-vue'


import './scss/bootstrap.scss'
import './style.css'

axios.defaults.baseURL = window.location.hostname === 'localhost' ? 'http://localhost:8402' : 'https://api.teamhaze.de'

axios.interceptors.request.use((config) => {
    if(localStorage.getItem('access') != null)
        config.headers.Authorization = localStorage.getItem('access')
    return config;
});

axios.interceptors.response.use((response) => {
    return response
}, (error) => {
    if(localStorage.getItem('access') === null)
        return Promise.resolve(error)
    console.log(error)
    if(error.response && error.response.status === 401){
        localStorage.clear()
        app.config.globalProperties.$router.push('/')
        app.$toast.show({title: 'Keine Berechtigung', color: 'error', message: 'Du hast darauf keine Berechtigung'})
        return
    }
    if(error.code === 'ERR_BAD_REQUEST')
        return Promise.resolve(error.response)
    if(error.code === 'ERR_NETWORK') {
        app.$toast.show({title: 'Fehler', color: 'warning', message: 'Es schein ein Problem mit der Internetverbindung zu geben'})
        return Promise.reject(error)
    }
    return Promise.resolve(error.response)
});

import './lib/chart/chart.min.js';
import api from "@/plugins/api";
import toast from '@/plugins/toast';
//import './lib/easing/easing.min.js';
//import './lib/waypoints/waypoints.min.js';
//import './lib/owlcarousel/owl.carousel.min.js';
//import './lib/tempusdominus/js/moment.min.js';
//import './lib/tempusdominus/js/moment-timezone.min.js';
//import './lib/tempusdominus/js/tempusdominus-bootstrap-4.min.js';
import './lib/chart/chart.min.js'

const app = createApp(App)
    .use(toast)
    .use(VueAxios, axios)
    .use(api)
    .use(router)
    .use(FloatingVue)
router.isReady().then(() => {
    api.isReady().then(() => {
        app.mount('#app')
    })
})

export default app
